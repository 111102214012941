import React from 'react';
import styled from '@emotion/styled';

import sleep from 'assets/others/aboutUs/sleep.jpg';
import listen from 'assets/others/aboutUs/listen.jpg';
import meditation from 'assets/others/aboutUs/meditation.jpg';
import move from 'assets/others/aboutUs/move.jpg';
import Checkmark from 'assets/svgs/about_us_checkmark.svg';
import { BREAKPOINTS, MOBILE_SIDE_PADDING, WEB_SIDE_PADDING } from 'utils/constants';

const CATEGORIES_DATA = [
  {
    title: 'Sleep stories',
    image: sleep,
    content: [
      'Hours of ultra-relaxing sleep stories to help beat insomnia and to fall into a quicker, deeper sleep state every single night. We’ll have you dreaming in no time, providing a better quality of slumber so you feel refreshed every time you wake.',
      'Sleep-inducing and positive narratives about travel, mental health, fairy tales and fantasy, classic novels, and body image and LGBT+ themes are added weekly.',
      'Your favorite celebrities lend their voices and tell their very own stories focused on positive mental health experiences.',
      'For pregnant users, especially created sleep stories and mindfulness tips to follow and provide companionship through your life changing three trimesters.',
      'Specially designed sleep stories for children to wind down to and drift off to an imaginary place. They’ll shake off the feelings of the day and will feel well-rested for a more productive day tomorrow and beyond.',
    ],
  },
  {
    title: 'Move',
    image: move,
    content: [
      'Hundreds of hours of yoga videos guided by the best yoga teachers in the world in aspirational landscapes, from in-depth courses to dip-in, dip-out quick fixes.',
      'Fitness experts guide you through simple and effective training techniques to shape, tone, relieve muscle pain and stress and find strength of mind.',
    ],
  },
  {
    title: 'Meditation and Mind',
    image: meditation,
    content: [
      'Mindfulness meditation courses and breathing rituals train you to lower stress levels and channel your focus on the present moment.',
      'Very latest news, research and tips from psychology experts in monthly blogs providing insight in everything mental health related.',
      'Put questions to our in-house psychologists on weekly topics.',
    ],
  },
  {
    title: 'Listen',
    image: listen,
    content: [
      'Play a library of ambient soundscapes to suit your mood. From relaxing lo-fi and dreamy nature sounds to help you switch off, to super-charged tunes and tantalising ASMR to awaken the mind, body and senses.',
    ],
  },
];

interface ICategoryImage {
  image: string;
}

interface ICategoryContainer {
  index: number;
  isLast: boolean;
}

const Container = styled.div({
  padding: `116px ${WEB_SIDE_PADDING} 20px ${WEB_SIDE_PADDING}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  [BREAKPOINTS[800]]: {
    padding: `116px ${MOBILE_SIDE_PADDING} 20px ${MOBILE_SIDE_PADDING}`,
  },
});

const Title = styled.div((props) => ({
  color: props.theme.colors.white,
  fontSize: 40,
  letterSpacing: 2,
  fontWeight: 600,
  textAlign: 'center',
}));

const TitleLight = styled.span({
  fontWeight: 100,
});

const Categories = styled.div({
  width: '100%',
  maxWidth: 970,
  marginTop: 60,
});

const CategoryContainer = styled.div<ICategoryContainer>((props) => ({
  display: 'flex',
  flexDirection: props.index % 2 !== 0 ? 'row-reverse' : 'row',
  justifyContent: 'space-between',
  padding: '52px 0',
  borderBottom: props.isLast ? '' : '1px solid rgba(255, 255, 255, 0.08)',
  [BREAKPOINTS[955]]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const CategoryImage = styled.div<ICategoryImage>((props) => ({
  minWidth: 367,
  minHeight: 338,
  width: 367,
  height: 338,
  backgroundImage: `url(${props.image})`,
  backgroundPosition: 'center center',
  backgroundSize: '100% auto',
  backgroundRepeat: 'no-repeat',
  borderRadius: 4,
  [BREAKPOINTS[955]]: {
    minWidth: 0,
    minHeight: 0,
  },
}));

const CategoryTextContainer = styled.div({
  maxWidth: 450,
  paddingLeft: 16,
  [BREAKPOINTS[955]]: {
    paddingLeft: 0,
  },
});

const CategoryTitle = styled.div((props) => ({
  color: props.theme.colors.white,
  fontSize: 24,
  letterSpacing: 2,
  marginBottom: 24,
  fontWeight: 500,
  [BREAKPOINTS[955]]: {
    marginTop: 40,
  },
}));

const CategoryPointContainer = styled.div({
  display: 'flex',
  marginBottom: 8,
});

const CheckmarkContainer = styled.div({
  marginTop: 4,
  marginRight: 8,
});

const CategoryPointText = styled.div((props) => ({
  color: props.theme.colors.white,
  opacity: 0.8,
  fontSize: 14,
  lineHeight: '28px',
  letterSpacing: 1,
}));

const Content: React.FC = () => (
  <Container>
    <Title>
      What you can
      <TitleLight> expect to find</TitleLight>
    </Title>
    <Categories>
      {CATEGORIES_DATA.map((category, index) => (
        <CategoryContainer
          isLast={CATEGORIES_DATA.length - 1 === index}
          index={index}
          key={category.title}
        >
          <CategoryImage image={category.image} />
          <CategoryTextContainer>
            <CategoryTitle>{category.title}</CategoryTitle>
            {category.content.map((text) => (
              <CategoryPointContainer key={text}>
                <CheckmarkContainer>
                  <Checkmark />
                </CheckmarkContainer>
                <CategoryPointText>{text}</CategoryPointText>
              </CategoryPointContainer>
            ))}
          </CategoryTextContainer>
        </CategoryContainer>
      ))}
    </Categories>
  </Container>
);

export default Content;
