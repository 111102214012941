import React from 'react';
import styled from '@emotion/styled';

import cover from 'assets/others/aboutUs/cover.jpg';
import { BREAKPOINTS, MOBILE_SIDE_PADDING, WEB_SIDE_PADDING } from 'utils/constants';

const Container = styled.div({
  backgroundImage: `url(${cover})`,
  backgroundPosition: 'center top',
  backgroundSize: '100% auto',
  backgroundRepeat: 'no-repeat',
  height: 550,
  padding: `180px ${WEB_SIDE_PADDING} 30px ${WEB_SIDE_PADDING}`,
  [BREAKPOINTS[1350]]: {
    backgroundSize: 'auto 100%',
    backgroundAttachment: 'unset',
  },
  [BREAKPOINTS[800]]: {
    padding: `100px ${MOBILE_SIDE_PADDING} 0 ${MOBILE_SIDE_PADDING}`,
    height: 'auto',
  },
});

const TextWrapper = styled.div({
  maxWidth: 600,
  [BREAKPOINTS[800]]: {
    paddingBottom: 80,
  },
});

const Title = styled.div((props) => ({
  fontSize: 40,
  lineHeight: '52px',
  letterSpacing: 2,
  color: props.theme.colors.white,
  [BREAKPOINTS[870]]: {
    fontSize: 32,
  },
}));

const Subtitle = styled(Title)({
  fontWeight: 100,
});

const Content = styled.div((props) => ({
  fontSize: 14,
  fontWeight: 200,
  letterSpacing: 1,
  lineHeight: '28px',
  marginTop: 40,
  color: props.theme.colors.white,
}));

const Hero: React.FC = () => (
  <Container>
    <TextWrapper>
      <Title>You can and will become a better version of yourself</Title>
      <Subtitle>with a few daily life hacks.</Subtitle>
      <Content>
        Through specially developed meditation rituals, sleep stories, yoga practices, soundscapes,
        and more, our aim is to provide an irresistible safe haven where you can take guilt-free
        time for yourself. Everyday.
        <br />
        <br />
        And the best bit is that it’s all tailored to you. There’s no need to wade through videos
        and audio that aren’t for you, because who’s got time for that? Using clever technology,
        we’ll find the best programme based on your habits and needs to help body and mind. We know
        it can be tricky to carve out that five minutes in a day to be mindful but allow us to
        become your personal assistant enabling new rituals to develop a deep and caring
        relationship with yourself. The choice is Yours.
      </Content>
    </TextWrapper>
  </Container>
);

export default Hero;
